import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";

const Search = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission behavior
      onSearch(searchQuery);
    }
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <form>
      <div className='relative mt-2 rounded-md shadow-sm'>
        <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
          <FaSearch className='h-5 w-5 text-gray-400' aria-hidden='true' />
        </div>
        <input
          type='text'
          className='block w-full h-16 border-2 border-gray-700 rounded-md py-1.5 pl-10 text-gray-900 bg-ml-light placeholder:text-gray-400 text-xl sm:leading-6'
          placeholder='Search teachings'
          value={searchQuery}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      </div>
    </form>
  );
};

export default Search;
