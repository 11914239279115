// import Home from "./screens/Home";
import Index from "./screens/Index";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import Contact from "./screens/Contact";
import Writings from "./screens/Writings";

import { ApolloProvider } from "@apollo/client/react";
import client from "./lib/apollo";
import Updates from "./screens/Updates";
import Teaching from "./screens/Teaching";

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Routes>
          {/* <Route exact path='/' element={<Index />} /> */}
          <Route exact path='/' element={<Index />} />
          <Route exact path='/about' element={<Home />} />
          <Route exact path='/updates' element={<Updates />} />
          <Route exact path='/writing' element={<Writings />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/teaching' element={<Teaching />} />
        </Routes>
      </Router>
    </ApolloProvider>
  );
}

export default App;
