import React, { useState, useEffect } from "react";
import { FadeIn, FadeInStagger } from "../FadeIn";
import FullWidthCarousel from "../slider";
import Search from "./searchbar_teachings";
import { createClient } from "contentful";

const Teaching = () => {
  const [posts, setPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          !process.env.REACT_APP_CONTENTFUL_SPACE_ID ||
          !process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
        ) {
          console.error("Contentful space ID or access token is not set.");
          return;
        }

        const client = createClient({
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });

        const response = await client.getEntries({
          content_type: "post",
          query: searchQuery,
          "fields.categoryName": "Teachings", // Add this line to filter by categoryName
        });

        if (response.items) {
          const items = response.items.map((item) => {
            const {
              title,
              date,
              excerpt,
              content,
              categoryName,
              featuredImage,
            } = item.fields;
            const excerptText = excerpt?.content
              .map((block) => block.content.map((text) => text.value).join(""))
              .join("\n");
            const contentText = content?.content
              .map((block) => block.content.map((text) => text.value).join(""))
              .join("\n");
            const imageUrl = featuredImage?.fields.file.url;
            return {
              title,
              date,
              excerpt: excerptText,
              content: contentText,
              categoryName,
              featuredImage: imageUrl,
            };
          });
          setPosts(items);
        }
      } catch (error) {
        console.error("Error fetching data from Contentful:", error);
      }
    };

    fetchData();
  }, [searchQuery]);

  // Handle search query changes
  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const filteredPosts = posts.filter(
    ({ title, content }) =>
      title?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      content?.toLowerCase()?.includes(searchQuery.toLowerCase())
  );

  // Display message based on the number of records found
  const displayMessage = searchQuery
    ? filteredPosts.length > 0
      ? `Found ${filteredPosts.length} result(s) for ${searchQuery}`
      : "No results found"
    : "";

  return (
    <div className='App text-ml-gray-dark'>
      <header>
        <div className='mx-auto p-5'>
          <FadeIn>
            <FullWidthCarousel />
          </FadeIn>
        </div>
        <div className='mx-auto max-w-7xl p-5'>
          <FadeInStagger>
            <FadeIn>
              <div className='px-5 py-5 md:px-8 md:py-10 rounded-lg shadow-lg bg-ml-blue-light border-2 border-ml-gray-dark'>
                <h1 className='font-semibold text-left text-4xl'>Syllabi</h1>
                <p className='md:text-xl text-left py-3'>
                  I approach teaching with compassion for students of diverse
                  accessibility needs and backgrounds, adaptability for
                  different teaching modalities, and open communication as the
                  foundational principle to create transformative learning
                  exchanges in the classroom. At ASU, I am an interdisciplinary,
                  engaging and student-centered professor who empowers students
                  to express their ideas by providing a range of course
                  assignments that include formal papers and discussion boards,
                  while also promoting creative assignments such as ‘Zines,
                  poetry, and short films. My student-centered pedagogy is meant
                  to demystify difficult theory and make it a collaborative
                  process.
                </p>

                {/* Include the Search component and pass the handleSearch function */}

                <Search onSearch={handleSearch} />
                <div className='text-center text-md text-ml-gray-dark mt-4'>
                  {displayMessage}
                </div>
                <div className='mx-auto max-w-7xl p-5'>
                  <div>
                    {posts.length > 0 ? (
                      posts.map((post, index) => (
                        <div
                          key={index}
                          className='px-5 py-5 md:px-8 md:py-10 rounded-lg shadow-lg bg-ml-blue-light my-4'
                        >
                          <div className='grid md:grid-cols-2 gap-8'>
                            <div>
                              {post.featuredImage && (
                                <img
                                  src={post.featuredImage}
                                  alt={post.title}
                                  className='rounded-lg'
                                />
                              )}
                            </div>
                            <div>
                              <h2 className='font-semibold text-left text-3xl py-3'>
                                {post.title}
                              </h2>
                              <p className='sr-only'>{post.date}</p>
                              <p className='py-1 text-xl'>{post.content}</p>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No posts found.</p>
                    )}
                  </div>
                </div>
              </div>
            </FadeIn>
          </FadeInStagger>
        </div>
      </header>
    </div>
  );
};

export default Teaching;
