import React from "react";

import Layout from "../components/Layout";

import Writing from "../components/writings/Writing";

const Writings = () => {
  return (
    <Layout>
      <Writing />
    </Layout>
  );
};

export default Writings;
