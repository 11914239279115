export default function HHomeHead() {
  return (
    <div className='mx-auto max-w-7xl p-5'>
      <div className='relative px-5 py-5 md:px-8 md:py-20 rounded-lg shadow-lg bg-ml-blue-light border-2 border-ml-gray-dark'>
        <div className='mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end'>
          <div className='lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2'>
            <div className='relative h-[36rem] lg:h-auto lg:w-full lg:grow'>
              <img
                className='absolute inset-0 w-full h-full object-cover md:object-top rounded-lg lg:rounded-none'
                src='https://kats-port.s3.us-west-1.amazonaws.com/20240422_drmelli8751.jpg'
                alt='headshot of mellissa linton-villafranco'
              />
              <p className='absolute bottom-0 left-0 bg-ml-navy text-white text-sm p-2 rounded-md'>
                Portraits by Caitlin O'Hara
              </p>
            </div>
          </div>
          <div className='px-6 lg:contents'>
            <div className='mx-auto max-w-1xl pb-16 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2'>
              <h1 className='mt-2 pb-3 text-3xl font-bold tracking-tight text-gray-900 md:text-5xl'>
                Dr. Mellissa Linton-Villafranco is an Assistant Professor of
                Women and Gender Studies in the School of Social Transformation
                at Arizona State University.
              </h1>
              {/* <p className='mt-6 pb-3 text-xl leading-8 text-gray-900'>
                Her forthcoming book{" "}
                <span className='font-bold'>
                  A Nation Reborn: Reproductive Justice in El Salvador
                </span>{" "}
                is an interdisciplinary study of post-civil war El Salvador and
                how national conceptions of reproduction, sexuality, and
                environmentalism were reconstituted in both law and culture
                after the specter of the cold war passed.
              </p>
              <p className='mt-6 pb-3 text-xl leading-8 text-gray-900'>
                At ASU, she is the faculty coordinator for the Undergraduate
                LGBT Studies Certificate, a faculty affiliate with the School of
                Transborder Studies, and a faculty advisor in Barrett the Honors
                College. Her research utilizes reproductive justice in both
                theory and praxis.
              </p>
              <p className='mt-6 pb-3 text-xl leading-8 text-gray-900'>
                She received her Ph.D. in Ethnic Studies from UC San Diego, and
                her B.A. in English Literature and American Studies & Ethnicity
                from the University of Southern California, where she graduated
                Magna Cum Laude and was a McNair and Discovery Scholar.
              </p> */}
              <a href='/contact' alt='contact' className='py-6'>
                <button
                  type='button'
                  className='mt-6 rounded-full bg-ml-navy px-3.5 py-1.5 text-xl text-ml-light shadow-sm hover:bg-ml-light hover:text-black hover:border-black hover:border-2 border-2 border-bg-ml-navy'
                >
                  Contact
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
