import React from "react";
import { FadeIn } from "../FadeIn";

import BUpdates from "./BUpdates";
import MlList from "./list";
import Mlimages from "./gallery";

const MUpdate = () => {
  return (
    <div className='App'>
      <header>
        <div className='mx-auto max-w-7xl p-5'>
          <FadeIn>
            <div className='px-5 py-5 md:px-8 md:py-10 rounded-lg shadow-lg bg-ml-blue-light border-2 border-ml-gray-dark'>
              <div className='mb-6'>
                <Mlimages />
                <h3 className='font-semibold text-right text-2xl py-1 mb-3'>
                  El Salvador 2024
                </h3>
              </div>
              <h3 className='font-semibold text-left text-4xl py-1'>
                Conferences & Talks
              </h3>

              <div className='mb-6'>
                <MlList />
              </div>
              <h3 className='font-semibold text-left text-4xl'>Updates</h3>

              <BUpdates />
            </div>
          </FadeIn>
        </div>
      </header>
    </div>
  );
};

export default MUpdate;
