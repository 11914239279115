import { useState, useEffect } from "react";
import { fetchEvents } from "../../lib/CFapi";
import { NavLink } from "react-router-dom";

export default function MlList() {
  const [lists, setLists] = useState([]);
  const [error, setError] = useState(null); // Added state for error handling

  useEffect(() => {
    const fetchSt = async () => {
      try {
        const items = await fetchEvents();
        setLists(items);
      } catch (err) {
        setError(err.message); // Catch and set any errors that occur during fetch
        console.error("Failed to fetch events:", err);
      }
    };
    fetchSt();
  }, []);

  if (error) {
    return <div>Error loading events: {error}</div>; // Render error message if error occurs
  }

  return (
    <div className='overflow-hidden rounded-md  shadow'>
      <ul className='divide-y divide-gray-400'>
        {lists.map((list) => (
          <li key={list.title} className='px-6 py-4 text-black text-xl'>
            <p>
              {list.year}: <span className='px-1 '>{list.title}</span>
              {list.excerpt} (
              <NavLink
                to={list.url}
                target='_blank'
                rel='noreferrer'
                className='text-ml-blue-dark hover:underline px-1'
              >
                link
              </NavLink>
              )
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}
