import React from "react";
import "../App.css";
import "animate.css";

const Index = () => {
  return (
    <div className='App'>
      <header className='App-header bg-hero3 bg-center bg-cover bg-no-repeat'>
        <div
          className='header-content animate__animated animate__fadeIn px-6 py-12 max-w-7xl'
          style={{
            background:
              "linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8))",
          }}
        >
          <p className='md:text-3xl font-semibold text-left py-3 text-white'>
            <span className='text-6xl mr-1 tracking-tight text-white'>
              Dr. Mellissa Linton-Villafranco{" "}
            </span>
            is an Assistant Professor of Women and Gender Studies in the School
            of Social Transformation at Arizona State University.
          </p>{" "}
          <a href='/about' alt='contact' className='py-6'>
            <button
              type='button'
              className='mt-6 rounded-full mr-5 px-3.5 py-1.5 text-2xl font-semibold text-ml-light shadow-sm hover:bg-ml-light hover:text-black hover:border-black hover:border-2 border-2 border-bg-ml-navy'
            >
              About
            </button>
          </a>
        </div>
      </header>
    </div>
  );
};

export default Index;
