export default function Testimonial() {
  return (
    <section className='mx-auto max-w-7xl p-5'>
      <div className='px-5 py-5 md:px-8 md:py-20 rounded-lg shadow-lg bg-ml-blue-light border-2 border-ml-gray-dark'>
        {" "}
        <div className='md:flex justify-between'>
          <h2 className='text-3xl font-bold tracking-tight text-black sm:text-4xl'>
            Student Course Evaluations
          </h2>{" "}
          <img
            className='h-12 '
            src='/asu-block.png'
            alt='Arizona State University'
          />
        </div>
        <div className='md:grid md:grid-cols-2 '>
          <div className='px-6 py-12 md:flex md:flex-col md:border-r md:border-gray-300 md:py-16 md:pl-0 md:pr-10 lg:pr-16'>
            <blockquote className='mt-6 md:flex md:flex-grow md:flex-col'>
              <div className='relative text-lg font-medium text-white md:flex-grow'>
                <svg
                  className='absolute left-0 top-0 h-6 w-6 -translate-x-3 -translate-y-5 md:-translate-y-2 transform text-black'
                  fill='currentColor'
                  viewBox='0 0 32 32'
                  aria-hidden='true'
                >
                  <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z' />
                </svg>

                <p className='relative mt-4'>
                  Dr. Linton is student-centered and cares about the success of
                  each student. She focuses her instruction on the core concepts
                  and theoretical lens of the readings while also engaging
                  students from their research interests. Her feminist pedagogy
                  fits perfectly within WST.
                  <svg
                    className='inline-block h-6 w-6 ml-1 transform text-black'
                    fill='currentColor'
                    viewBox='0 0 32 32'
                    aria-hidden='true'
                  >
                    <path
                      d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z'
                      transform='scale(-1, 1) translate(-32, 0)'
                    />
                  </svg>
                </p>
              </div>
            </blockquote>
          </div>
          <div className='border-t-2 border-indigo-900 px-6 py-12 md:border-l md:border-t-0 md:py-16 md:pl-10 md:pr-0 lg:pl-16'>
            <blockquote className='mt-6 md:flex md:flex-grow md:flex-col'>
              <div className='relative text-lg font-medium text-white md:flex-grow'>
                <svg
                  className='absolute left-0 top-0 h-6 w-6 -translate-x-3 -translate-y-5 md:-translate-y-2 transform text-black'
                  fill='currentColor'
                  viewBox='0 0 32 32'
                >
                  <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z' />
                </svg>
                <p className='relative md:mt-4'>
                  The effort that the Professor made to make sure that we as the
                  students got the most out of this course. The readings were
                  phenomenal and engaging in a way I did not know possible. In
                  fact, I bought all of the books and plan to read them the
                  whole way through.
                  <svg
                    className='inline-block h-6 w-6 ml-1 transform text-black'
                    fill='currentColor'
                    viewBox='0 0 32 32'
                    aria-hidden='true'
                  >
                    <path
                      d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z'
                      transform='scale(-1, 1) translate(-32, 0)'
                    />
                  </svg>
                </p>
              </div>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
}
