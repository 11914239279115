import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import "animate.css";
import { NavLink } from "react-router-dom";

const navigation = [
  { name: "ABOUT", href: "/about" },
  { name: "TEACHING", href: "/teaching" },
  { name: "WRITING", href: "/writing" },
  { name: "UPDATES", href: "/updates" },
  { name: "CONTACT", href: "/contact" },
];

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header>
      <nav
        className='mx-auto flex items-center justify-between p-6 lg:px-8'
        aria-label='Global'
      >
        <div className='flex lg:flex-1'>
          <a href='/' className='-m-1.5 p-1.5'>
            <span className='sr-only'>Mellissa Linton-Villafranco</span>
            <h1 className='font-semibold text-4xl md:text-6xl mr-1 tracking-tight text-black'>
              Mellissa Linton-Villafranco, Ph.D
            </h1>
          </a>
        </div>
        <div className='flex lg:hidden'>
          <button
            type='button'
            className='-m-2.5 inline-flex md:items-center md:justify-center justify-right rounded-md p-2.5 text-black'
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className='sr-only'>Open main menu</span>
            <Bars3Icon className='h-12 w-12' aria-hidden='true' />
          </button>
        </div>
        <div className='hidden lg:flex lg:gap-x-6'>
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive }) =>
                isActive
                  ? "text-sm font-bold leading-6 text-ml-tan active"
                  : "text-sm font-semibold leading-6 text-black"
              }
              exact
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </nav>
      <Dialog
        as='div'
        className='lg:hidden'
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className='fixed inset-0 z-100' />
        <Dialog.Panel className='animate__animated animate__fadeIn bg-cover bg-hero bg-ml-blue-light fixed inset-y-0 right-0 z-10 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center justify-between'>
            <a href='/about' className='-m-1.5 p-1.5'>
              <span className='sr-only'>Mellissa Linton-Villafranco, Ph.D</span>
              <h1 className='font-semibold text-4xl md:text-7xl mr-1 tracking-tight text-black'>
                Mellissa Linton-Villafranco, Ph.D
              </h1>
            </a>
            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-black'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <XMarkIcon className='h-12 w-12' aria-hidden='true' />
            </button>
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6'>
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      isActive
                        ? "-mx-3 block rounded-lg px-3 py-2 text-sm font-bold leading-6 text-ml-tan active"
                        : "-mx-3 block rounded-lg px-3 py-2 text-sm font-semibold leading-6 text-black"
                    }
                    exact
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
