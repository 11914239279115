import React from "react";

import Layout from "../components/Layout";
import MUpdate from "../components/updates/Update";

const Updates = () => {
  return (
    <Layout>
      <MUpdate />
    </Layout>
  );
};

export default Updates;
