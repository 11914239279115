import "animate.css";
import Navbar from "./Navbar";
import Footer from "./footer";
import "../App.css";

export default function Layout({ children }) {
  return (
    <div>
      <Navbar />

      <div className='flex flex-col min-h-screen animate__animated animate__fadeIn '>
        <div className='mx-auto'></div>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
}
