export default function StudentResources() {
  return (
    <div className='mx-auto max-w-7xl p-5'>
      <div className='overflow-hidden px-5 py-5 md:px-8 md:py-20 rounded-lg shadow-lg bg-ml-blue-light border-2 border-ml-gray-dark'>
        <div className='px-6 lg:flex lg:px-8'>
          <div className='grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8'>
            <div className='lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8'>
              <h2 className='text-3xl font-bold tracking-tight text-black sm:text-4xl'>
                Student Resources
              </h2>
              <p className='mt-6 text-xl leading-8'>
                Here you will find course syllabi, as well as examples of
                student projects and theses.
              </p>

              <div className='mt-10 flex'>
                <a
                  href='/teaching'
                  className='mt-6 rounded-full bg-ml-navy px-3.5 py-1.5 text-xl text-ml-light shadow-sm hover:bg-ml-light hover:text-black hover:border-black hover:border-2 border-2 border-bg-ml-navy'
                >
                  Access Resources
                </a>
              </div>
            </div>
            <div className='flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents'>
              <div className='w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end'>
                <img
                  src='/LGBTCertificate.jpg'
                  alt=''
                  className='aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
