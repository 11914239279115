import React from "react";
import Form from "../components/contact/Form";
import Layout from "../components/Layout2";
import StudentResources from "../components/about/Students";
import { FadeInStagger } from "../components/FadeIn";

const Contact = () => {
  return (
    <div>
      <Layout>
        <FadeInStagger>
          <Form />
          <StudentResources />
        </FadeInStagger>
      </Layout>
    </div>
  );
};

export default Contact;
