import React, { useState, useEffect } from "react";
import { FadeIn } from "../FadeIn";
import Search from "./searchbar_writings";
import { createClient } from "contentful";

const Writing = () => {
  const [posts, setPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          !process.env.REACT_APP_CONTENTFUL_SPACE_ID ||
          !process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
        ) {
          console.error("Contentful space ID or access token is not set.");
          return;
        }

        const client = createClient({
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });

        const response = await client.getEntries({
          content_type: "post",
          query: searchQuery,
          "fields.categoryName": "Writings", // Add this line to filter by categoryName
        });

        if (response.items) {
          const items = response.items.map((item) => {
            const {
              title,
              date,
              excerpt,
              content,
              categoryName,
              featuredImage,
              url,
            } = item.fields;
            const excerptText = excerpt?.content
              .map((block) => block.content.map((text) => text.value).join(""))
              .join("\n");
            const contentText = content?.content
              .map((block) => block.content.map((text) => text.value).join(""))
              .join("\n");
            const imageUrl = featuredImage?.fields.file.url;
            return {
              title,
              date,
              excerpt: excerptText,
              content: contentText,
              categoryName,
              featuredImage: imageUrl,
              url,
            };
          });
          setPosts(items);
        }
      } catch (error) {
        console.error("Error fetching data from Contentful:", error);
      }
    };

    fetchData();
  }, [searchQuery]);
  // Handle search query changes
  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const filteredPosts = posts.filter(
    ({ title, content }) =>
      title?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      content?.toLowerCase()?.includes(searchQuery.toLowerCase())
  );

  // Display message based on the number of records found
  const displayMessage = searchQuery
    ? filteredPosts.length > 0
      ? `Found ${filteredPosts.length} result(s) for ${searchQuery}`
      : "No results found"
    : "";

  return (
    <div className='App'>
      <header>
        <div className='mx-auto max-w-7xl p-5'>
          <FadeIn>
            <div className='px-5 py-5 md:px-8 md:py-10 rounded-lg shadow-lg bg-ml-blue-light border-2 border-ml-gray-dark'>
              <h1 className='font-semibold text-left text-4xl'>Writing</h1>
              <p className='md:text-lg text-left py-3'></p>
              <Search onSearch={handleSearch} />
              <div className='text-center text-md text-ml-gray-dark mt-4'>
                {displayMessage}
              </div>
              <div className='mx-auto max-w-7xl p-5 text-ml-blue-dark grid md:grid-cols-2'>
                {posts.length > 0 ? (
                  posts.map((post, index) => (
                    <div
                      key={index}
                      className='px-5 py-5 md:px-8 md:py-10 rounded-lg shadow-lg bg-ml-blue-light bg-opacity-90 m-4'
                    >
                      <div>
                        <div className=''>
                          {post.featuredImage && (
                            <img
                              src={post.featuredImage}
                              alt={post.title}
                              className='w-full rounded-lg'
                            />
                          )}
                        </div>
                        <div>
                          <h2 className='font-semibold text-left text-3xl py-3'>
                            {post.title}
                          </h2>
                          <p className='sr-only'>{post.date}</p>
                          <p className='py-1 text-xl'>{post.excerpt}</p>
                        </div>
                        <div className='py-3 font-semibold'>
                          {post.url && (
                            <a
                              href={post.url}
                              target='_blank'
                              rel='noreferrer'
                              className='py-1 mt-3 text-sm uppercase'
                            >
                              Read More
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No posts found.</p>
                )}
              </div>
            </div>
          </FadeIn>
        </div>
      </header>
    </div>
  );
};

export default Writing;
