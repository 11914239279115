import React, { useEffect, useState } from "react";
import { FadeIn } from "./FadeIn";
import { fetchCarouselItems } from "../lib/CFapi";

const FullWidthCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselItems, setCarouselItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const items = await fetchCarouselItems();
      setCarouselItems(items);
    };
    fetchData();
  }, []);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % carouselItems.length);
    }, 20000);

    return () => clearInterval(intervalId);
  }, [currentIndex, carouselItems.length]);

  return (
    <div className='max-w-7xl mx-auto my-6 py-6 rounded-xl bg-ml-blue-light border-2 border-ml-gray-dark'>
      <h3 className='font-semibold text-left text-4xl px-5'>
        Featured Student Projects
      </h3>
      {carouselItems.map((item, index) => (
        <div key={index} className='slide rounded-lg'>
          <div>
            <div
              className={` mx-auto rounded-lg ${
                index === currentIndex ? "block" : "hidden"
              }`}
            >
              {" "}
              <FadeIn>
                <div id='slider' className='p-5 rounded-xl '>
                  <div className='p-3'>
                    <img
                      src={item.image}
                      alt='student work'
                      className='mx-auto object-cover rounded-md w-full'
                    />
                  </div>

                  <div className='px-4'>
                    <h3 className='text-2xl font-semibold py-1 md:mt-3 opacity-100'>
                      {item.title}
                    </h3>
                    <p className='text-xl mt-5mr-10 opacity-100 py-1'>
                      {item.description}
                    </p>
                    <a
                      href={item.url}
                      target='_blank'
                      rel='noreferrer'
                      className='text-sm mt-5mr-10 opacity-100 py-1 uppercase font-bold text-ml-blue-dark'
                    >
                      Visit Project
                    </a>
                  </div>
                </div>
              </FadeIn>
            </div>
          </div>
        </div>
      ))}
      <div className='navigation-dots mx-auto max-w-7xl'>
        {carouselItems.map((_, index) => (
          <button
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};
export default FullWidthCarousel;
