import { useState, useEffect } from "react";
import { fetchPhotos } from "../../lib/CFapi";

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

export default function Mlimages() {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPh = async () => {
      try {
        const items = await fetchPhotos();
        const shuffledItems = shuffleArray([...items]); // Clone and shuffle
        setFiles(shuffledItems);
      } catch (err) {
        setError(err.message);
        console.error("Failed to fetch photos:", err);
      }
    };
    fetchPh();
  }, []);

  if (error) {
    return <div>Error loading photos: {error}</div>;
  }
  return (
    <ul className='masonry-layout columns-2 md:columns-3 lg:columns-4 xl:columns-4 mb-6'>
      {files.map((file) => (
        <li key={file.source} className='break-inside-avoid mb-2'>
          <div className='relative'>
            <img
              src={file.image}
              alt={file.caption}
              className='w-full h-auto rounded-md'
              style={{ objectFit: "contain" }}
            />
            <div className='absolute inset-0 bg-black bg-opacity-0 flex justify-center items-center opacity-0 hover:bg-opacity-50 hover:opacity-100 transition-opacity duration-300'>
              <p className='text-white text-sm text-wrap text-center text-bold'>
                {file.caption}
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
