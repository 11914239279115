import React, { useEffect, useState, useRef } from "react";
import { createClient } from "contentful";
import { FadeIn, FadeInStagger } from "../FadeIn";

function BUpdates() {
  const [posts, setPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(6);
  const [scrollToNewPosts, setScrollToNewPosts] = useState(false); // New state to control scrolling
  const [searchQuery, setSearchQuery] = useState("");
  const newPostsRef = useRef(null);

  // Scroll to new posts when the flag is set
  useEffect(() => {
    if (scrollToNewPosts && newPostsRef.current) {
      window.scrollTo({
        top: newPostsRef.current.offsetTop - 50, // Adjust the offset as needed
        behavior: "smooth",
      });
      setScrollToNewPosts(false); // Reset the flag
    }
  }, [scrollToNewPosts]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          !process.env.REACT_APP_CONTENTFUL_SPACE_ID ||
          !process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
        ) {
          console.error("Contentful space ID or access token is not set.");
          return;
        }

        const client = createClient({
          space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
          accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
        });

        const response = await client.getEntries({
          content_type: "post",
          query: searchQuery,
        });

        if (response.items) {
          const items = response.items.map((item) => {
            const {
              title,
              date,
              excerpt,
              content,
              categoryName,
              featuredImage,
            } = item.fields;
            const excerptText = excerpt?.content
              .map((block) => block.content.map((text) => text.value).join(""))
              .join("\n");
            const contentText = content?.content
              .map((block) => block.content.map((text) => text.value).join(""))
              .join("\n");
            const imageUrl = featuredImage?.fields.file.url;
            return {
              title,
              date,
              excerpt: excerptText,
              content: contentText,
              categoryName,
              featuredImage: imageUrl,
            };
          });
          setPosts(items);
        }
      } catch (error) {
        console.error("Error fetching data from Contentful:", error);
      }
    };

    fetchData();
  }, [searchQuery]);

  const loadMore = () => {
    setVisiblePosts(visiblePosts + 6);
    setScrollToNewPosts(true); // Set the flag to scroll to new post
  };

  return (
    <>
      <FadeInStagger>
        <FadeIn>{/* <Search onSearch={handleSearch} /> */}</FadeIn>
        <FadeIn>
          <ul className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 py-6'>
            {posts.slice(0, visiblePosts).map((post, index) => (
              <li key={index}>
                <div className='max-w-lg rounded overflow-hidden shadow-lg bg-ml-blue-light'>
                  {post.featuredImage && (
                    <img
                      src={post.featuredImage}
                      alt={post.title}
                      className='rounded-t-lg'
                    />
                  )}
                  <div className='px-6 py-4'>
                    <div className='font-bold text-xl mb-2'>{post.title}</div>
                    <p className='sr-only'>{post.date}</p>
                    <p className='py-1 text-xl'>{post.excerpt}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {visiblePosts < posts.length && (
            <div className='text-center'>
              <button
                className='mt-6 rounded-full bg-ml-navy px-3.5 py-1.5 text-xl text-ml-light shadow-sm hover:bg-ml-light hover:text-black hover:border-black hover:border-2 border-2 border-bg-ml-navy'
                onClick={loadMore}
              >
                Load more
              </button>
            </div>
          )}
        </FadeIn>
      </FadeInStagger>
    </>
  );
}

export default BUpdates;
