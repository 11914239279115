import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

export const fetchEvents = async () => {
  try {
    const response = await client.getEntries({
      content_type: "events",
    });
    if (response.includes && response.includes.Asset) {
      const items = response.items.map((item) => {
        const { year, title, excerpt, url } = item.fields;
        return {
          year,
          title,
          excerpt,
          url,
        };
      });
      return items;
    } else {
      return response.items.map((item) => {
        const { year, title, excerpt, url } = item.fields;
        return {
          year,
          title,
          excerpt,
          url,
        };
      });
    }
  } catch (error) {
    console.error("Error fetching events:", error);
    return []; // Return an empty array in case of error
  }
};

export const fetchPhotos = async () => {
  try {
    const response = await client.getEntries({
      content_type: "fieldwork",
    });
    const assets = response.includes.Asset.reduce((acc, asset) => {
      acc[asset.sys.id] = asset.fields.file.url; // Map asset IDs to their URLs
      return acc;
    }, {});
    const items = response.items.map((item) => {
      const { caption, image } = item.fields;
      return {
        caption,
        image: assets[image.sys.id], // Use mapped URL
      };
    });
    return items;
  } catch (error) {
    console.error("Error fetching carousel items:", error);
    return []; // Return an empty array in case of error
  }
};

export const fetchCarouselItems = async () => {
  try {
    const response = await client.getEntries({
      content_type: "featureSlideshow",
    });
    const assets = response.includes.Asset.reduce((acc, asset) => {
      acc[asset.sys.id] = asset.fields.file.url; // Map asset IDs to their URLs
      return acc;
    }, {});
    const items = response.items.map((item) => {
      const { title, description, image, url } = item.fields;
      return {
        title,
        description,
        image: assets[image.sys.id], // Use mapped URL
        url,
      };
    });
    return items;
  } catch (error) {
    console.error("Error fetching carousel items:", error);
    return []; // Return an empty array in case of error
  }
};
