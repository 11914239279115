import React from "react";
import Layout from "../components/Layout2";
import { FadeInStagger } from "../components/FadeIn";
import Testimonial from "../components/about/testimonials";
import StudentResources from "../components/about/Students";
import HHomeHead from "../components/about/Home_header";

const Home = () => {
  return (
    <Layout>
      <FadeInStagger>
        <HHomeHead />
        <StudentResources />
        <Testimonial />
      </FadeInStagger>
    </Layout>
  );
};

export default Home;
